import Keys from "../Keys/Results.views4.keys";

const InitialState = {
  question1: [],
  question2: [],
  question3: [],
  question4: [],
  about_user: { role: "", workplace: "", country: "", state: "" },
  question2_other:'',
  question4_other:''
};

export const ResultsViews4Reducer = (state = { ...InitialState }, action) => {
  switch (action.type) {
    case Keys.QUESTION1:
      return { ...state, question1: action.value };
    case Keys.QUESTION2:
      return { ...state, question2: action.value };
    case Keys.QUESTION3:
      return { ...state, question3: action.value };
    case Keys.QUESTION4:
      return { ...state, question4: action.value };
    case Keys.UPDATE_ABOUT_USER:
      return { ...state, about_user: action.value };
    case Keys.QUESTION2_OTHER:
      return { ...state, question2_other: action.value };
    case Keys.QUESTION4_OTHER:
      return { ...state, question4_other: action.value };
    default:
      return state;
  }
};
