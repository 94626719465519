import img1 from "../Assets/Images/exemples/image.png";

import dry from "../Assets/Images/exemples/dry.png";
import flowers from "../Assets/Images/exemples/flowers.png";
import normal from "../Assets/Images/exemples/normal.png";
import oil from "../Assets/Images/exemples/oil.png";

import sounds from "../Assets/sounds/exemple.mp3";

const data = {
  Starting: {
    title: "How do you feel about beauty brands?",
    image: { url: img1, alt: "beauty brands" },
    description: `Today we’re going to be asking your thoughts on your beauty brands. There are no right or wrong answers.`,
  },
  Question1: {
    title: "What beauty brand do you “like” “boost” on social?",
    hint: "Please choose up to 3",
    answers: [
      "Fenty Beauty",
      "MAC",
      "Lady Gaga Haus Labs ",
      "Charlotte Tilbury",
      "ColourPop",
      "Other (fill in)",
    ],
  },
  Question2: {
    title: "What Beauty Brands have you purchased because of social? ",
    // hint: "We all have different ways we need to take care of our skin",
    answers: [
      {
        placeholder:
          "________________________________________________________________________________________",
        name: "ans1",
        label: "Beauty Brand 1",
      },
      {
        placeholder:
          "________________________________________________________________________________________",
        name: "ans2",
        label: "Beauty Brand 2",
      },
      {
        placeholder:
          "________________________________________________________________________________________",
        name: "ans3",
        label: "Beauty Brand 3",
      },
    ],
  },
  Question3: {
    title:
      "If I see a beauty product or other product that is interesting on social, I  ",
    hint: "Choose as many as you want",
    answers: [
      "Go online and do more research",
      "Go to my favorite beauty store",
      "Go to Amazon",
      "Go to the brand website ",
      "Order from an influencer link or post ",
      "Other (fill in)",
    ],
  },
  Question4: {
    title: "About you",
    part1: {
      question: "Age",
      answers: [
        "Under 18",
        "18-24",
        "25-34",
        "35-44",
        "45-54",
        "55-64",
        "65+",
        "Prefer not to say",
      ],
    },
    part2: {
      question: "My Identity",
      answers: [
        "Female",
        "Male",
        "Trans",
        "Non-binary",
        "GNC",
        "Prefer not to say",
      ],
    },
    part3: {
      question: "Where are you from ?",
    },
  },
  Result: {
    title: "Thanks for Vibing! ",
    hint: "You’re a Trend-fluencer!",
  },
};

export default data;
