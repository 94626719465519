import React from "react";
import styles from "./buttons.module.scss";

export function Button(props) {
  const { children, className, onClick } = props;
  return (
    <button onClick={onClick} className={`${styles.button} ${className}`}>
      {children}
    </button>
  );
}

export function ButtonOutline(props) {
  const { children, className, onClick } = props;
  return (
    <button
      onClick={onClick}
      className={`${styles.button_outline} ${className}`}
    >
      {children}
    </button>
  );
}
