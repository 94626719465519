import React, { useState, useEffect } from "react";
import { useParams, Navigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import {
  fetchSurvey,
  getSurveyId,
  fetchVibe,
  getSurveyQuestions,
  getFetchStatus,
  checkIfVibeURLValid,
  getCheckingStatus
} from '../src/redux/Reducers/Surveys.reducer'

import { MainPage } from "./views/MainPage/MainPage";
import { MainViews4Page } from "./views4/MainPage/MainViews4Page";
import { MainViews5Page } from "./views5/MainPage/MainViews5Page"

export const MainRoute = () => {

  let { vibeId } = useParams();

  const dispatch = useDispatch()
  let location = useLocation();

  useEffect(() => {
    if (vibeId) {
      dispatch(fetchVibe(vibeId))
    }
  }, [vibeId, dispatch])

  const surveyId = useSelector(getSurveyId)

  const isValidVibe = useSelector(checkIfVibeURLValid)
  const isChecking = useSelector(getCheckingStatus)

  if (!isValidVibe && !isChecking) {
    return <Navigate to="/soon" state={{ from: location}} replace />
  }

    if(surveyId === 3) {
      return <MainViews5Page/>
    } else if(surveyId === 2) {
      return <MainViews4Page/>
    } else if(surveyId === 1) {
      return <MainPage/>
    }
    
}

export default MainRoute;
