import React, { useEffect } from "react";
import styles from "./styles.module.scss";

import { H2 } from "../../../Components/Texts/Textes";
import { Button } from "../../../Components/buttons/Buttons";
import Logo from "../../../Assets/Images/logo/logo_orange2.png";

import Data from "../../../data/fake.views4.data";

import { useSelector, useDispatch } from "react-redux";
import coffeti_actions from "../../../redux/Actions/Cofetti.action";

import { saveViews4Result, getSurveyId, getBrandId, getInfluencerId } from '../../../redux/Reducers/Surveys.reducer'

function Result(props) {
  const resultViews4 = useSelector((state) => state.ResultsViews4Reducer);
  const dispatch = useDispatch();
  const { questions, done } = props;

  const surveyId = useSelector(getSurveyId)
  const brandId = useSelector(getBrandId)
  const influencerId = useSelector(getInfluencerId)

  useEffect(() => {
    if (done) {
      dispatch(saveViews4Result({brandId, influencerId, surveyId, questions, resultViews4}));
    }
  }, [resultViews4]);

  useEffect(() => {
    if (done) {
      setTimeout(() => {
        dispatch(coffeti_actions.start_cofettiInfinity());
      }, 1000);
    }
  }, [done]);

  const handle_finish = () => {
    window.open("http://vibeviews.com", "_blank");
  };

  const data = Data.Result;

  return (
    <div className={`${styles.question} ${styles.result}`}>
      <H2>{data.title}</H2>
      <span className={styles.info}>{data.hint}</span>

      <div className={styles.logo_container}>
        <img src={Logo} alt="VibeViews" />
      </div>

      <Button onClick={handle_finish} className={styles.result_btn}>
        Go to VibeViews.com
      </Button>
    </div>
  );
}

export default Result;
