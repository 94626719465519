import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'

import styles from "./mainPage.module.scss";
import toast from "react-hot-toast";

import logo from "../../Assets/Images/logo/new_logo.svg";

import Stepper from "../../Components/stepper/Stepper";

import {
  fetchSurvey,
  getSurveyId,
  getSurveyQuestions,
  getFetchStatus,
} from '../../redux/Reducers/Surveys.reducer'

import Starting from "./Sections/Starting";
import Question1 from "./Sections/Question1";
import Question2 from "./Sections/Question2";
import Question3 from "./Sections/Question3";
import Question4 from "./Sections/Question4";
import Question5 from "./Sections/Question5";
import Result from "./Sections/Result";


export const MainViews5Page = () => {

  const dispatch = useDispatch()

  const surveyId = useSelector(getSurveyId)

  useEffect(() => {
    if (surveyId) {
      dispatch(fetchSurvey(surveyId))
    }
  }, [surveyId, dispatch])

  const [step, set_step] = useState(0);
  const [max, set_max] = useState(0);
  const number_of_steps = 6;
  const questions = useSelector(getSurveyQuestions)
  const isLoading = useSelector(getFetchStatus)

  if (isLoading) {
    return <div>Loading...</div>
  }

  const handle_next = () => {
    if (step < steps.length) {
      if (max === step) {
        set_max(step + 1);
      }
      set_step(step + 1);
    }
  };

  const handle_prev = () => {
    if (step > 0) {
      set_step(step - 1);
    }
  };

  const handleStop = () => {
    set_step(number_of_steps);
    set_max(number_of_steps);
  };

  const handle_choose = (stp) => {
    if (max !== number_of_steps) {
      if (stp === 1 && max === 0) {
        set_max(1);
        set_step(1);
      } else if (stp <= max) {
        set_step(stp);
      } else {
        toast.error(
          "Yo, please fill something in before you can move forward."
        );
      }
    }
  };

  const steps = [
    <Starting handle_next={handle_next} />,
    <Question1
      question={questions[0]}
      handle_next={handle_next}
      handle_prev={handle_prev}
      handleStop={handleStop}
    />,
    <Question2
      question={questions[1]}
      handle_next={handle_next}
      handle_prev={handle_prev}
      handleStop={handleStop}
    />,
    <Question3
      question={questions[2]}
      handle_next={handle_next}
      handle_prev={handle_prev}
      handleStop={handleStop}
    />,
    <Question4
      question={questions[3]}
      handle_next={handle_next}
      handle_prev={handle_prev}
      handleStop={handleStop}
    />,
    <Question5
      handle_next={handle_next}
      handle_prev={handle_prev}
      handleStop={handleStop}
    />,
    <Result questions={questions} done={step === number_of_steps} />,
  ];

  return (
    <div className={styles.main}>
      <div className={styles.background}>
        <div className={styles.logo_container}>
          <img src={logo} alt="vibeviews" />
        </div>

        <div className={styles.container}>
          <Stepper
            step={max}
            actual={step}
            onChange={handle_choose}
            done={step === number_of_steps}
          />

          <div className={styles.carossel}>
            <div
              className={styles.slider}
              style={{ transform: `translateX(-${step * 100}%)` }}
            >
              {steps.map((item, key) => {
                return (
                  <div key={key} className={styles.item}>
                    {item}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
