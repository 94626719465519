import React, { useState, useRef } from "react";
import { useEffect } from "react";
import toast from "react-hot-toast";

import styles from "./inputs.module.scss";
import arrow from "../../Assets/Images/icons/up_arrow.svg";

import { get_flags, FindStates } from "../../services/countries.services";

import useOutsideEvent from "../../Hooks/useOutsideEvent";

export function SelectCounties(props) {
  const { onChange } = props;
  const [val, setVal] = useState({
    img: null,
    full_name: "Country",
    code: null,
  });
  const [open, setOpen] = useState(false);
  const [countries, set_countries] = useState([]);

  const select_ref = useRef(null);
  useOutsideEvent(select_ref, () => {
    setOpen(false);
  });

  useEffect(() => {
    get_flags(
      (data) => {
        set_countries(data);
      },
      (error) => {
        toast.error("error in retreaving countries data");
      }
    );
  }, []);

  const handle_open = () => {
    setOpen(!open);
  };

  const handle_select = (item) => {
    setVal(item);
    setOpen(false);
    onChange(item);
  };

  return (
    <div className={styles.select} ref={select_ref}>
      <div className={styles.label} onClick={handle_open}>
        {val.img && (
          <div className={styles.img_container}>
            <img src={val.img} alt={val.full_name} />
          </div>
        )}
        <span>{val.full_name}</span>
        <img
          src={arrow}
          className={`${styles.down_arrow} ${open ? styles.arrow_up : ""} `}
          alt="Country"
        />
      </div>
      {open && (
        <div className={styles.items}>
          {countries.map((item, key) => {
            return (
              <div
                key={key}
                className={styles.item}
                onClick={() => {
                  handle_select(item);
                }}
              >
                <div className={styles.img_container}>
                  <img src={item.img} alt={item.full_name} />
                </div>
                <span>{item.full_name}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export function SelectStates(props) {
  const { code, onChange } = props;
  const [val, setVal] = useState({ name: "State" });
  const [open, setOpen] = useState(false);
  const [states, set_states] = useState([]);

  const select_ref = useRef(null);
  useOutsideEvent(select_ref, () => {
    setOpen(false);
  });

  useEffect(() => {
    if (code && ['🇺🇸', '🇨🇦', '🇲🇽'].includes(code)) {
      FindStates(code, set_states);
    } else {
      set_states([]);
    }
  }, [code]);

  const handle_open = () => {
    setOpen(!open);
  };

  const handle_select = (item) => {
    setVal(item);
    setOpen(false);
    onChange(item);
  };

  return (
    <div className={styles.select} ref={select_ref}>
      <div className={styles.label} onClick={handle_open}>
        <span>{val.name}</span>
        <img
          src={arrow}
          className={`${styles.down_arrow} ${open ? styles.arrow_up : ""} `}
          alt="state"
        />
      </div>
      {open && (
        <div className={styles.items}>
          {states.map((item, key) => {
            return (
              <div
                key={key}
                className={styles.item}
                onClick={() => {
                  handle_select(item);
                }}
              >
                <span>{item.name}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
