import { configureStore } from '@reduxjs/toolkit';

import SurveysReducer from "../Reducers/Surveys.reducer"
import { ResultsReducer } from "../Reducers/Results.reducer";
import { ResultsViews4Reducer } from "../Reducers/Results.views4.reducer";
import { ResultsViews5Reducer } from "../Reducers/Results.views5.reducer";
import { CofettiReducer } from "../Reducers/Cofetti.reducer";

export const store = configureStore({
    reducer: {
        surveys: SurveysReducer,
        ResultsReducer: ResultsReducer,
        ResultsViews4Reducer: ResultsViews4Reducer,
        ResultsViews5Reducer: ResultsViews5Reducer,
        CofettiReducer: CofettiReducer
    }
});
