const QUESTION1 = "QUESTION1";
const QUESTION2 = "QUESTION2";
const QUESTION3 = "QUESTION3";
const QUESTION4 = "QUESTION4";
const UPDATE_ABOUT_USER = "UPDATE_ABOUT_USER";
const QUESTION3_OTHER = "QUESTION3_OTHER";

export default {
  QUESTION1,
  QUESTION2,
  QUESTION3,
  QUESTION4,
  UPDATE_ABOUT_USER,
  QUESTION3_OTHER
};
