import React from "react";

import logo from "../../Assets/Images/logo/logo_coming_soon.png";
import { H2, H3, H5 } from "../../Components/Texts/Textes";
import styles from "../MainPage/mainPage.module.scss";
import './Pages.scss';

export const ComingSoon = () => {

  return (
            <div className={styles.main}>
              <div className={styles.background}>
                <div>
                  <p className="TextAlign">
                    <img src={logo} />
                  </p>
              </div>
               <div className={styles.container}>
                <div>
                  <p className="TextAlign">
                    <H2>Coming Soon</H2>
                  </p>
                  <p className="TextAlign">
                    <H3>Get ready, something cool is coming!</H3>
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
}
