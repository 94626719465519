import React from "react";

import styles from "./textes.module.scss";

export function H1(props) {
  const { children, className } = props;
  return <h1 className={`${styles.h1} ${className}`}>{children}</h1>;
}

export function H2(props) {
  const { children, className } = props;
  return <h2 className={`${styles.h2} ${className}`}>{children}</h2>;
}

export function H3(props) {
  const { children, className } = props;
  return <h3 className={`${styles.h3} ${className}`}>{children}</h3>;
}

export function H4(props) {
  const { children, className } = props;
  return <h4 className={`${styles.h4} ${className}`}>{children}</h4>;
}

export function H5(props) {
  const { children, className } = props;
  return <h5 className={`${styles.h5} ${className}`}>{children}</h5>;
}

export function P(props) {
  const { children, className } = props;
  return <p className={`${styles.p} ${className}`}>{children}</p>;
}
