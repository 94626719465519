import React, { useState, useEffect } from "react";
import { useParams, Navigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'

import styles from "./mainPage.module.scss";
import toast from "react-hot-toast";

import logo from "../../Assets/Images/logo/logo_white.svg";

import Stepper from "../../Components/stepper/Stepper";

import {
  fetchSurvey,
  getSurveyId,
  fetchVibe,
  getSurveyQuestions,
  getFetchStatus,
  checkIfVibeURLValid,
  getCheckingStatus
} from '../../redux/Reducers/Surveys.reducer'

import Starting from "./Sections/Starting";
import Question1 from "./Sections/Question1";
import Question2 from "./Sections/Question2";
import Question3 from "./Sections/Question3";
import Question4 from "./Sections/Question4";
import Result from "./Sections/Result";

export const MainPage = () => {

  const dispatch = useDispatch()

  const surveyId = useSelector(getSurveyId)

  useEffect(() => {
    if (surveyId) {
      dispatch(fetchSurvey(surveyId))
    }
  }, [surveyId, dispatch])

  const [step, set_step] = useState(0);
  const [max, set_max] = useState(0);

  const questions = useSelector(getSurveyQuestions)
  const isLoading = useSelector(getFetchStatus)

  if (isLoading) {
    return <div>Loading...</div>
  }

  const handle_next = () => {
    if (step < steps.length) {
      if (max === step) {
        set_max(step + 1);
      }
      set_step(step + 1);
    }
  };

  const handle_prev = () => {
    if (step > 0) {
      set_step(step - 1);
    }
  };

  const handle_choose = (stp) => {
    if (max != 5) {
      if (stp === 1 && max === 0) {
        set_max(1);
        set_step(1);
      } else if (stp <= max) {
        set_step(stp);
      } else {
        toast.error(
          "Yo, please fill something in before you can move forward."
        );
      }
    }
  };

  const steps = []
  steps.push(<Starting handle_next={handle_next} />)
  questions.map((question, index) => {
    if (index < 1) {
      steps.push(<Question1 question={question} handle_next={handle_next} handle_prev={handle_prev} />)
    } else if (index == 1) {
      steps.push(<Question2 question={question} handle_next={handle_next} handle_prev={handle_prev} />)
    } else if (index == 2) {
      steps.push(<Question3 question={question} handle_next={handle_next} handle_prev={handle_prev} />)
    }
  })
  steps.push(<Question4 handle_next={handle_next} handle_prev={handle_prev} />)
  steps.push(<Result questions={questions} done={step === 5} />)
  return (
    <div className={styles.main}>
      <div className={styles.background}>
        <div className={styles.logo_container}>
          <img src={logo} alt="vibeviews" />
        </div>

        <div className={styles.container}>
          <Stepper
            step={max}
            actual={step}
            onChange={handle_choose}
            done={step === 5}
          />

          <div className={styles.carossel}>
            <div
              className={styles.slider}
              style={{ transform: `translateX(-${step * 100}%)` }}
            >
              {steps.map((item, key) => {
                return (
                  <div key={key} className={styles.item}>
                    {item}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
