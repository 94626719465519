import Keys from "../Keys/Cofetti.keys";

const start_cofetti = () => {
  return (dispatch) => {
    dispatch({
      type: Keys.START,
    });

    setTimeout(() => {
      dispatch({
        type: Keys.STOP,
      });
    }, 4000);
  };
};

const start_cofetti10 = () => {
  return (dispatch) => {
    dispatch({
      type: Keys.START,
    });

    setTimeout(() => {
      dispatch({
        type: Keys.STOP,
      });
    }, 10000);
  };
};

const start_cofettiInfinity = () => {
  return (dispatch) => {
    dispatch({
      type: Keys.START,
    });
  };
};

const actions = {
  start_cofetti,
  start_cofetti10,
  start_cofettiInfinity,
};

export default actions;
