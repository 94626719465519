import Keys from "../Keys/Results.keys";

const InitialState = {
  question1: [],
  question2: [],
  question3: [],
  about_user: { age: "", sex: "", country: "", state: "" },
  question1_other: '',
  question3_other:''
};

export const ResultsReducer = (state = { ...InitialState }, action) => {
  switch (action.type) {
    case Keys.QUESTION1:
      return { ...state, question1: action.value };
    case Keys.QUESTION2:
      return { ...state, question2: action.value };
    case Keys.QUESTION3:
      return { ...state, question3: action.value };
    case Keys.UPDATE_ABOUT_USER:
      return { ...state, about_user: action.value };
    case Keys.QUESTION1_OTHER:
      return { ...state, question1_other: action.value };
    case Keys.QUESTION3_OTHER:
      return { ...state, question3_other: action.value };
    default:
      return state;
  }
};
