import axios from 'axios';
import decode from 'jwt-decode';

const fetcher = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': localStorage.session
  }
});

export const saveResult = (surveyId, result, brandId, influencerId) => {
  return fetcher.post(`/vibes/${surveyId}/results/with_brand/${brandId}/with_infuence/${influencerId}`, result).then(res => res.data);
};

export const fetchSurvey = (surveyId) => {
  return fetcher.get(`/surveys/${surveyId}`).then(res => res.data);
};

export const fetchVibe = (vibeId) => {
  return fetcher.get(`/vibes/${vibeId}`).then(res => res.data);
};

export const fetchBrand = (brandId) => {
  return fetcher.get(`/brands/${brandId}`).then(res => res.data);
};

export const fetchInfluencer = (influencerId) => {
  return fetcher.get(`/influencers/${influencerId}`).then(res => res.data);
};

const api = {
  fetchSurvey,
  fetchVibe,
  fetchBrand,
  fetchInfluencer,
  saveResult,
};

export default api;