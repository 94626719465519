import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import api from '../../data/api'

const initialState = {
  isLoading: false,
  isChecking: true,
  isSuccess: false,
  surveyId: '',
  brandId: '',
  influencerId: '',
  survey: '',
  results: [],
  error: '',
}

export const saveViews5Result = createAsyncThunk(
  'surveys/saveViews5Result',
  async ({brandId, influencerId, surveyId, questions, resultViews5}) => {
    let mapped_result = mapViews5Result({ questions, resultViews5 })
    const response = await api.saveResult(surveyId, mapped_result, brandId, influencerId)

    return response
})

export const saveViews4Result = createAsyncThunk(
  'surveys/saveViews4Result',
  async ({brandId, influencerId, surveyId, questions, resultViews4}) => {
    let mapped_result = mapViews4Result({ questions, resultViews4 })
    const response = await api.saveResult(surveyId, mapped_result, brandId, influencerId)

    return response
})

export const saveResult = createAsyncThunk(
  'surveys/saveResult',
  async ({brandId, influencerId, surveyId, questions, result}) => {
    let mapped_result = mapResult({ questions, result })
    const response = await api.saveResult(surveyId, mapped_result, brandId, influencerId)

    return response
})

export const fetchSurvey = createAsyncThunk(
  'surveys/fetchSurvey',
  async (surveyId) => {
    const response = await api.fetchSurvey(surveyId)

    return response
})

export const fetchVibe = createAsyncThunk(
  'surveys/fetchVibe',
  async (vibeId) => {
    const response = await api.fetchVibe(vibeId)

    return response
})

export const fetchBrand = createAsyncThunk(
  'surveys/fetchBrand',
  async (brandId) => {
    const response = await api.fetchBrand(brandId)

    return response
})

export const fetchInfluencer = createAsyncThunk(
  'surveys/fetchInfluencer',
  async (influencerId) => {
    const response = await api.fetchInfluencer(influencerId)

    return response
})

const surveysSlice = createSlice({
  name: 'surveys',
  initialState,
  extraReducers: {
    [fetchSurvey.fulfilled]: (state, action) => {
      state.survey = action.payload
      state.isLoading = false
      state.error = ''
    },
    [fetchSurvey.pending]: (state, action) => {
      state.isLoading = true
      state.error = ''
    },
    [fetchSurvey.rejected]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
    [fetchBrand.fulfilled]: (state, action) => {
      state.brand = action.payload
    },
    [fetchVibe.fulfilled]: (state, action) => {
      if (action.payload !== null) {
        let {brand_id, influencer_id, survey_id } = action.payload
        state.surveyId = survey_id
        state.brandId = brand_id
        state.influencerId = influencer_id
      }
      state.isChecking = false
    },
    [fetchInfluencer.fulfilled]: (state, action) => {
      state.influencer = action.payload
    },
    [saveResult.fulfilled]: (state, action) => {
      state.isSuccess = true
    },
    [saveViews4Result.fulfilled]: (state, action) => {
      state.isSuccess = true
    },
    [saveViews5Result.fulfilled]: (state, action) => {
      state.isSuccess = true
    },
  },
})

const mapViews5Result = ({questions, resultViews5}) => {
  let q1 = questions[0];
  let q2 = questions[1];
  let q3 = questions[2];
  let q4 = questions[3];

  let q5_age = questions[4];
  let q6_gender = questions[5];
  let q7_country = questions[6];
  let q8_state = questions[6];
  let q3_other = questions[7];

  let about_user = resultViews5.about_user
  let age = q5_age.options.find(o => o.content === about_user.age)
  let gender = q6_gender.options.find(o => o.content === about_user.gender)
  let cu = about_user.country
  let st = about_user.state

  let res = {}
  if (resultViews5.question1.length != 0) res[q1._id] = resultViews5.question1
  if (resultViews5.question2.length != 0) res[q2._id] = resultViews5.question2
  if (resultViews5.question3.length != 0) res[q3._id] = resultViews5.question3
  if (resultViews5.question4.length != 0) res[q4._id] = resultViews5.question4

  if (age !== undefined) res[q5_age._id] = [age._id]
  if (gender !== undefined) res[q6_gender._id] = [gender._id]
  if (cu.full_name !== undefined) res[q7_country._id] = cu.full_name
  if (st.name !== undefined) res[q8_state._id] = st.name

  if (resultViews5.question3_other !== "") res[q3_other._id] = resultViews5.question3_other

  return res
};

const mapViews4Result = ({questions, resultViews4}) => {
  let q1 = questions[0];
  let q2 = questions[1];
  let q3 = questions[2];
  let q4 = questions[3];

  let q5_role = questions[4];
  let q6_workplace = questions[5];
  let q7_country = questions[6];
  let q8_state = questions[6];
  let q2_other = questions[7];
  let q4_other = questions[8];

  let about_user = resultViews4.about_user
  let role = q5_role.options.find(o => o.content === about_user.role)
  let workplace = q6_workplace.options.find(o => o.content === about_user.workplace)
  let cu = about_user.country
  let st = about_user.state

  let res = {}
  if (resultViews4.question1.length != 0) res[q1._id] = resultViews4.question1
  if (resultViews4.question2.length != 0) res[q2._id] = resultViews4.question2
  if (resultViews4.question3.length != 0) res[q3._id] = resultViews4.question3
  if (resultViews4.question4.length != 0) res[q4._id] = resultViews4.question4

  if (role !== undefined) res[q5_role._id] = [role._id]
  if (workplace !== undefined) res[q6_workplace._id] = [workplace._id]
  if (cu.full_name !== undefined) res[q7_country._id] = cu.full_name
  if (st.name !== undefined) res[q8_state._id] = st.name

  if (resultViews4.question2_other !== "") res[q2_other._id] = resultViews4.question2_other
  if (resultViews4.question4_other !== "") res[q4_other._id] = resultViews4.question4_other

  return res
};

const mapResult = ({questions, result}) => {
  let q1 = questions[0];
  let q2 = questions[1];
  let q3 = questions[2];
  let q4_age = questions[3];
  let q5_sex = questions[4];
  let q6_country = questions[5];
  let q7_state = questions[6];
  let q1_other = questions[7];
  let q3_other = questions[8];

  let about_user = result.about_user
  let age = q4_age.options.find(o => o.content === about_user.age)
  let sex = q5_sex.options.find(o => o.content === about_user.sex)
  let cu = about_user.country
  let st = about_user.state

  let res = {}
  if (result.question1.length != 0) res[q1._id] = result.question1
  if (result.question2.length != 0) res[q2._id] = result.question2.join(', ')
  if (result.question3.length != 0) res[q3._id] = result.question3

  if (age !== undefined) res[q4_age._id] = [age._id]
  if (sex !== undefined) res[q5_sex._id] = [sex._id]
  if (cu !== undefined) res[q6_country._id] = cu.full_name
  if (st !== undefined) res[q7_state._id] = st.name

  if (result.question1_other !== "") res[q1_other._id] = result.question1_other
  if (result.question3_other !== "") res[q3_other._id] = result.question3_other

  return res
};

const { reducer } = surveysSlice;

export default reducer;


export const getSurveyId = (state) => state.surveys.surveyId

export const getSurvey = (state) => state.surveys.survey

export const getBrandId = (state) => state.surveys.brandId

export const getInfluencerId = (state) => state.surveys.influencerId

export const getSurveyQuestions = (state) => state.surveys.survey.questions ?? [];

export const getFetchStatus = (state) => state.surveys.isLoading

export const getErrorStatus = (state) => state.surveys.error

export const getCheckingStatus = (state) => state.surveys.isChecking

export const checkIfVibeURLValid = (state) => {
  if (state.surveys.brandId && state.surveys.influencerId && state.surveys.surveyId) {
    return true
  } else {
    return false
  }
}

