import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ResultActions from "../../../redux/Actions/Results.action";
import coffeti_actions from "../../../redux/Actions/Cofetti.action";
import toast from "react-hot-toast";

import { H2 } from "../../../Components/Texts/Textes";
import { Button, ButtonOutline } from "../../../Components/buttons/Buttons";
import {
  CheckBox_Field,
  CheckBox_Field_other,
} from "../../../Components/input/CheckBox_Field";

import white_arrow from "../../../Assets/Images/icons/white_arrow.svg";
import blue_arrow from "../../../Assets/Images/icons/blue_arrow.svg";

import Data from "../../../data/fake.data";
import { have, SelectN, isAll } from "../../../Functions/Arrays.functions";

import styles from "./styles.module.scss";

function Question3(props) {
  const { question, handle_next, handle_prev } = props;

  const data = Data.Question3;
  const dispatch = useDispatch();

  const [selected, setSelected] = useState([-1, -1, -1, -1, -1, -1]);

  const [other, setOther] = useState("");
  const [other_file, setOther_file] = useState(null);

  const [isnon, setIsNon] = useState(false);

  const handle_choose = (key, id) => {
    setIsNon(false);
    let new_select = SelectN(selected, key);
    setSelected(new_select);
    if (!isAll(new_select, -1)) {
      dispatch(coffeti_actions.start_cofetti());
    }
  };
  const handle_none = () => {
    if (!isnon) {
      setIsNon(true);
      setSelected([-1, -1, -1, -1, -1, -1]);
      dispatch(coffeti_actions.start_cofetti());
    } else {
      setIsNon(false);
    }
  };

  const handle_other_change = (event) => {
    if (event.target.value.length >= 30) {
      toast.error("max is 30 characters");
      return;
    }
    setOther(event.target.value);
  };

  const handle_other_img_change = (event) => {
    setOther_file(event.target.files);
  };

  const handleSubitQuestion = () => {
    if (isAll(selected, -1) && !isnon) {
      toast.error("please select at least 1 option"); // snack
      return;
    }

    if (isnon) {
      handle_next();
      return;
    }

    let q2_answers = selected.filter(x => x !== -1).map(i => {
      let opt = question.options[i];
      return opt._id;
    })

    dispatch(
      ResultActions.ChangeQ3(q2_answers)
    );

    dispatch(
      ResultActions.ChangeQ3Other(other)
    );

    handle_next();
  };

  return (
    <div className={styles.question}>
      <H2>{data.title}</H2>
      <span className={styles.info}>{data.hint}</span>
      <div className={styles.form}>
        {data.answers.map((item, key) => {
          return (
            <div key={key}>
              {key < data.answers.length - 1 && (
                <CheckBox_Field
                  check={have(selected, key)}
                  onClick={() => {
                    handle_choose(key, question.options[key]._id);
                  }}
                >
                  {item}
                </CheckBox_Field>
              )}

              {key === data.answers.length - 1 && (
                <CheckBox_Field_other
                  check={have(selected, key)}
                  onClick={() => {
                    handle_choose(key, question.options[key]._id);
                  }}
                  onChange={handle_other_change}
                  value={other}
                  onImgChange={handle_other_img_change}
                >
                  {item}
                </CheckBox_Field_other>
              )}
            </div>
          );
        })}

        <CheckBox_Field check={isnon} onClick={handle_none}>
          None
        </CheckBox_Field>

        <div className={styles.buttons}>
          <ButtonOutline className={styles.prev} onClick={handle_prev}>
            <img src={blue_arrow} alt="previus" />
          </ButtonOutline>
          <Button className={styles.btn_next} onClick={handleSubitQuestion}>
            Next <img src={white_arrow} alt="next" />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Question3;
