import React from "react";
import styles from "./styles.module.scss";

import { H2, P } from "../../../Components/Texts/Textes";
import { Button } from "../../../Components/buttons/Buttons";

import Data from "../../../data/fake.data";

function Starting(props) {
  const { handle_next } = props;
  const data = Data.Starting;
  return (
    <div className={styles.starting}>
      <H2 className={styles.title}>{data.title}</H2>
      <div className={styles.image_container}>
        <img src={data.image.url} alt={data.image.alt} />
      </div>
      <div className={styles.desc_btn_container}>
        <P className={styles.desccription}>{data.description}</P>
        <Button onClick={handle_next} className={styles.start}>
          Let’s do this!
        </Button>
      </div>
    </div>
  );
}

export default Starting;
