import img1 from "../Assets/Images/exemples/views5new.jpg";

const data = {
  Starting: {
    title: `Taylor Swift-o-Meter`,
    image: { url: img1, alt: `nursing shortages` },
    description: `Welcome to Swift-o-Meter! Complete, share, and give feedback on our first-ever Swiftie vibe check.`,
  },

  Question1: {
    title: `1.	Which Taylor’s Version album is your favorite so far?`,
    hint: `Please choose 2`,
    answers: [
      `Fearless`,
      `Red`,
      `Speak Now`,
      `1989`,
      `Can’t wait for the rest `,
    ],
  },
  Question2: {
    title: `2.	Which of Taylor’s cats is your favorite ?`,
    hint: "Choose at least one.",
    answers: [
      `Love them all`,
      `I am allergic to cats`,
      `Benjamin Button`,
      `Olivia Benson`,
      "Meredith Grey",
    ],
  },
  Question3: {
    title: `3.	What makes you a next-level Swiftie ?`,
    hint: "Choose as many as you want.",
    answers: [
      `Wearing friendship bracelets `,
      `Taylor top artist on Spotify Wrapped`,
      `Attended Era’s Tour`,
      `Promoting all things Taylor on social`,
      "Other (fill in)",
    ],
  },
  Question4: {
    title: `Travis Kelsey`,
    hint: "Choose One.",
    answers: [
      `Yes, love it`,
      `I support Taylor’s choices`,
      `So far it seems okay `,
      `I am suspicious of anyone around Taylor `,
      `She should call me for advice`,
    ],
  },
  Question5: {
    title: `About you`,
    part1: {
      question: "Age",
      answers: [
        "under 18",
        "18-24",
        "25-34",
        "35-44",
        "45-54",
        "55-64",
        "65+",
        "prefer not to say",
      ],
    },
    part2: {
      question: "How I describe myself",
      answers: ["Female", "Male", "Trans", "Non-binary", "GNC", "Other"],
    },
    part3: {
      question: `Where are you from ?`,
    },
  },
  Result: {
    title: `Thanks for Vibing! `,
    hint: `You’re a Trend-fluencer!`,
  },
};

export default data;
