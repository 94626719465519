import React, { useEffect } from "react";
import styles from "./styles.module.scss";

import { H2 } from "../../../Components/Texts/Textes";
import { Button } from "../../../Components/buttons/Buttons";
import Logo from "../../../Assets/Images/logo/new_logo.svg";

import Data from "../../../data/fake.views5.data";

import { useSelector, useDispatch } from "react-redux";
import coffeti_actions from "../../../redux/Actions/Cofetti.action";

import { saveViews5Result, getSurveyId, getBrandId, getInfluencerId } from '../../../redux/Reducers/Surveys.reducer'

function Result(props) {
  const resultViews5 = useSelector((state) => state.ResultsViews5Reducer);
  const dispatch = useDispatch();
  const { questions, done } = props;

  const surveyId = useSelector(getSurveyId)
  const brandId = useSelector(getBrandId)
  const influencerId = useSelector(getInfluencerId)

  useEffect(() => {
    if (done) {
      dispatch(saveViews5Result({brandId, influencerId, surveyId, questions, resultViews5}));
    }
  }, [resultViews5]);

  useEffect(() => {
    if (done) {
      setTimeout(() => {
        dispatch(coffeti_actions.start_cofettiInfinity());
        window.location.replace('https://go.vibeviews.com/swiftie-signup');
      }, 1000);
    }
  }, [done]);


  const data = Data.Result;

  return (
    <div className={`${styles.question} ${styles.result}`}>
      <H2>{data.title}</H2>
      <span className={styles.info}>{data.hint}</span>

      <div className={styles.logo_container}>
        <img src={Logo} alt="VibeViews" />
      </div>

    </div>
  );
}

export default Result;
