import axios from "axios";
import countries from "../Assets/json/states.json";

/* code: "🇺🇸" ; full_name: "United States" ; img : "https://flagcdn.com/us.svg" */

const country_parser = (country) => {
  return {
    code: country.flag,
    img: country.flags.svg,
    full_name: country.name.common,
  };
};

export const get_flags = (succ, fail) => {
  axios
    .get("https://restcountries.com/v3.1/all")
    .then((response) => {
      let countries = response.data;
      let us_pos = -1;
      let us = country_parser(
        countries.find((country, index) => {
          us_pos = index;
          return country.flag === "🇺🇸";
        })
      );

      countries.splice(us_pos, 1);
      let sorted_countries = countries
        .map((country) => {
          return country_parser(country);
        })
        .sort((a, b) => a.full_name.localeCompare(b.full_name));
      sorted_countries[0] = us;
      succ(sorted_countries);
    })
    .catch((error) => {
      fail(error);
    });
};

export const FindStates = (code, succ) => {
  succ(
    countries.find((country) => {
      return country.emoji === code;
    }).states
  );
};
