import React from "react";
import styles from "./inputs.module.scss";

import check_dark_img from "../../Assets/Images/icons/check_dark.svg";
import other_img from "../../Assets/Images/icons/other.svg";

export function CheckBox_Field(props) {
  const { children, check, onClick } = props;
  return (
    <div
      onClick={onClick}
      className={`${styles.check_field} ${
        check ? styles.active : styles.not_active
      }`}
    >
      <div className={styles.checkbox}>
        {check && <img src={check_dark_img} alt="checkbox" />}
      </div>
      <div className={styles.label}>{children}</div>
    </div>
  );
}

// CheckBox_Field with image
export function CheckBox_Field_WI(props) {
  const { children, img, check, onClick } = props;
  return (
    <div
      onClick={onClick}
      className={`${styles.check_field} ${styles.check_field_wi} ${
        check ? styles.active : styles.not_active
      }`}
    >
      <div className={styles.checkbox}>
        {check && <img src={check_dark_img} alt="checkbox" />}
      </div>
      <div className={styles.label}>
        {check ? (
          <div className={styles.spacing} />
        ) : (
          <div className={styles.img_container}>
            <img src={img} alt={children} />
          </div>
        )}
        {children}
      </div>
    </div>
  );
}

export function CheckBox_Field_other(props) {
  const { children, check, onClick, onChange, onImgChange, value } = props;

  return (
    <div
      className={`${styles.check_other} ${
        check ? styles.active_o : styles.not_active_o
      }`}
    >
      <div
        onClick={onClick}
        className={`${styles.check_field} ${
          check ? styles.check_field_checked : ""
        }`}
      >
        <div
          className={`${styles.checkbox} ${
            check ? styles.active : styles.not_active
          }`}
        >
          {check && <img src={check_dark_img} alt="checkbox" />}
        </div>
        <div className={styles.label}>{children}</div>
      </div>

      {check && (
        <div className={styles.field_other}>
          <input
            type="text"
            value={value}
            onChange={onChange}
            placeholder="................................................"
          />
          <div className={styles.file_input_container}>
            <label htmlFor="file-upload">
              <img src={other_img} alt="other" />
            </label>
            <input id="file-upload" onChange={onImgChange} type="file" />
          </div>
        </div>
      )}
    </div>
  );
}
