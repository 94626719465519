import React from "react";
import styles from "./inputs.module.scss";

function TextField(props) {
  const { name, value, onChange, placeholder, label } = props;

  return (
    <div className={styles.input_container}>
      <label>{label}</label>
      <div className={styles.TextField}>
        <input
          type="text"
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          // placeholder="................................................"
        />
      </div>
    </div>
  );
}

export default TextField;
