import React from "react";
import styles from "./stepcheck.module.scss";

import check_img from "../../Assets/Images/icons/check.svg";

function StepCheck(props) {
  const { checked = false, hilighted = false, children, onClick, done } = props;
  return (
    <div
      onClick={onClick}
      className={`${styles.main} 
      ${checked ? styles.checked : ""} 
      ${hilighted ? styles.hilighted : ""}
      ${!done ? styles.clicable : ""}
      `}
    >
      <span>{children}</span>
      <img src={check_img} alt="done" />
    </div>
  );
}

export default StepCheck;
