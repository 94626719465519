import React, { useState } from "react";
import styles from "./styles.module.scss";
import toast from "react-hot-toast";

import { H2 } from "../../../Components/Texts/Textes";
import { Button, ButtonOutline } from "../../../Components/buttons/Buttons";

import white_arrow from "../../../Assets/Images/icons/white_arrow.svg";
import blue_arrow from "../../../Assets/Images/icons/blue_arrow.svg";
import { SelectAnswer } from "../../../Components/input/SelectBtn";

import Data from "../../../data/fake.views5.data";
import DoubleSelect from "../../../Components/input/Double_Select";

import { useDispatch } from "react-redux";
import ResultsViews5Actions from "../../../redux/Actions/Results.views5.action";

function Question5(props) {
  const { question, handle_next, handle_prev } = props;
  const dispatch = useDispatch();

  const [about, setAbout] = useState({
    age: "",
    gender: "",
    country: "",
    state: "",
  });

  const handleChange = (value, name) => {
    setAbout({ ...about, [name]: value });
  };

  const handle_submit = () => {
    if (about.age === "") {
      toast.error("select your age");
      return;
    }
    if (about.gender === "") {
      toast.error("select your gender");
      return;
    }
    if (about.country === "") {
      toast.error("select your country");
      return;
    }
    dispatch(ResultsViews5Actions.ChangeAbout_User(about));

    handle_next();
  };

  const data = Data.Question5;

  return (
    <div className={styles.question}>
      <H2>{data.title}</H2>

      <div className={styles.form}>
        <SelectAnswer onChange={handleChange} name="age" data={data.part1} />
        <SelectAnswer onChange={handleChange} name="gender" data={data.part2} />
        <DoubleSelect data={data.part3} onChange={handleChange} />

        <div className={styles.buttons}>
          <ButtonOutline className={styles.prev} onClick={handle_prev}>
            <img src={blue_arrow} alt="previus" />
          </ButtonOutline>
          <Button className={styles.btn_next} onClick={handle_submit}>
            Submit <img src={white_arrow} alt="next" />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Question5;
