import React from "react";
import StepCheck from "../step_check/StepCheck";

import styles from "./stepper.module.scss";

function Stepper(props) {
  const { step, actual, onChange, done } = props;
  let bars = [1, 2, 3, 4, 5];
  let step_checks = ["Intro", "Q1", "Q2", "Q3", "Q4", "Wrap"];

  return (
    <div className={styles.main}>
      <div className={styles.bars}>
        {bars.map((item, key) => {
          return (
            <div
              key={key}
              className={`${styles.bar} ${
                item <= step ? styles.activated : styles.not_activated
              } `}
            />
          );
        })}
      </div>
      <div className={styles.steps}>
        {step_checks.map((item, key) => {
          return (
            <StepCheck
              key={key}
              done={done}
              onClick={() => {
                onChange(key);
              }}
              checked={key <= step}
              hilighted={key === actual}
            >
              {item}
            </StepCheck>
          );
        })}
      </div>
    </div>
  );
}

export default Stepper;
