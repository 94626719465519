import { useState, useEffect } from "react";

const get_width = () => {
  return document.getElementById("root").clientWidth;
};

const get_height = () => {
  return document.getElementById("root").clientHeight;
};

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: get_width(),
    height: get_height(),
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: get_width(),
        height: get_height(),
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}

export default useWindowSize;
