import img1 from "../Assets/Images/exemples/new1.jpg";

const data = {
  Starting: {
    title: `This survey is for individuals who hire international workforce candidates to address current nursing shortages`,
    image: { url: img1, alt: `nursing shortages` },
    description: `Today we are going to ask you your thoughts on the international workforce you have hired.`,
  },

  Question1: {
    title: `1.	Is the recruitment of international workforce candidates part of your strategy for addressing current nursing shortages? `,
    hint: `Please choose 1`,
    answers: [`YES`, `NO`],
  },
  Question2: {
    title: `2.	Which statement reflects your current strategy for international nurse recruitment?`,
    answers: [
      `<span class="strong" >Direct :</span>  We recruit directly without the use of an agency or third party.`,
      `<span class="strong" >Placement firm :</span> We recruit solely using agencies or third-party resources where we are the employer.`,
      `<span class="strong" >Staffing firm :</span> We contract resources from staffing firms (who are the employer of the nurse).`,
      `<span class="strong" >Combination :</span> We recruit directly and use agency or third-party resources.`,
      "Other (fill in)",
    ],
  },
  Question3: {
    title: `Which statement describes your experience hiring international workforce candidates to fill nursing roles within your organization ?`,

    answers: [
      `Nurse candidates from outside the US typically onboard successfully into their clinical roles and have no trouble acclimating to work within our teams and care settings.`,
      `Nurse candidates from outside the US need <span class="strong" >some</span> additional support onboarding into their clinical roles to acclimate to work within our teams and care settings. `,
      `Nurse candidates from outside the US need <span class="strong" >significant</span> support onboarding into their clinical roles and often struggle to acclimate to work within our teams and care settings.`,
    ],
  },
  Question4: {
    title: `Which proficiencies below represent areas where your international nursing candidates could use more job-readiness training to successfully onboard and acclimate within your organizations?`,
    hint: "Choose as many as you want.",
    answers: [
      `English language proficiency`,
      `Core platform utilization (e.g. EHR, CPOE, PACS, etc.)`,
      `Clinical tech tools (e.g. EKG, bedside monitoring)`,
      `Emerging technologies (e.g. telehealth, RPM, AI/ML) `,
      `Regulatory compliance programs (e.g. HIPAA, Core Measures)`,
      `Documentation requirements`,
      `Care team collaboration`,
      `Human Resource programs (e.g. time tracking, benefits management)`,
      `Certification and/or continuing education`,
      "Other (fill in)",
    ],
  },
  Question5: {
    title: `About you`,
    part1: {
      question: `My role`,
      answers: [
        `Chief Nursing Officers (CNOs)`,
        `Chief Nursing Information Officers (CNIOs)`,
        `Nurse Case Managers`,
        `Nurse Managers`,
        `Nurse Recruiters`,
        `Human Resource Officers`,
        `Other`,
      ],
    },
    part2: {
      question: `My workplace`,
      answers: [
        `US Hospital`,
        `Health System`,
        `IDN`,
        `Large Clinic`,
        `Provider Practice`,
        `Other`,
      ],
    },
    part3: {
      question: `Where are you from ?`,
    },
  },
  Result: {
    title: `Thanks for Vibing! `,
    hint: `You’re a Trend-fluencer!`,
  },
};

export default data;
