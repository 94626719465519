import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ResultsViews4tActions from "../../../redux/Actions/Results.views4.action";
import toast from "react-hot-toast";

import { H2 } from "../../../Components/Texts/Textes";
import { Button, ButtonOutline } from "../../../Components/buttons/Buttons";
import { CheckBox_Field as CheckBoxField } from "../../../Components/input/CheckBox_Field";

import white_arrow from "../../../Assets/Images/icons/white_arrow.svg";
import blue_arrow from "../../../Assets/Images/icons/blue_arrow.svg";

import Data from "../../../data/fake.views4.data";
import { have, SelectN, isAll } from "../../../Functions/Arrays.functions";

import styles from "./styles.module.scss";

function Question3(props) {
  const { question, handle_next, handle_prev, handleStop } = props;
  const data = Data.Question3;
  const dispatch = useDispatch();

  const [selected, setSelected] = useState([-1]);

  const [isnon, setIsNon] = useState(false);

  const handle_choose = (key) => {
    setIsNon(false);
    let new_select = SelectN(selected, key);
    setSelected(new_select);
    // if (!isAll(new_select, -1)) {
    //   dispatch(coffeti_actions.start_cofetti());
    // }
  };

  const handleSubitQuestion = () => {
    if (isAll(selected, -1) && !isnon) {
      toast.error("please select at least 1 option"); // snack
      return;
    }

    if (isnon) {
      handle_next();
      return;
    }

    let answers = [];
    selected.forEach((pos) => {
      if (pos !== -1) {
        answers.push(data.answers[pos]);
      }
    });

    let q3_answers = selected.filter(x => x !== -1 && x !== 4).map(i => {
      let opt = question.options[i];
      return opt._id;
    })
    dispatch(
      ResultsViews4tActions.ChangeQ3(q3_answers)
    );

    if (
      answers.length > 0 &&
      answers[0].indexOf(
        "Nurse candidates from outside the US typically onboard successfully into their clinical roles and have no trouble acclimating to work within our teams and care settings."
      ) !== -1
    ) {
      handleStop();
      toast.success("Thank you for your time");
      return;
    }

    handle_next();
  };

  return (
    <div className={styles.question}>
      <H2>{data.title}</H2>
      <span className={styles.info}>{data.hint}</span>
      <div className={styles.form}>
        {data.answers.map((item, key) => {
          let markup = { __html: item };
          return (
            <div key={key}>
              {
                <CheckBoxField
                  check={have(selected, key)}
                  onClick={() => {
                    handle_choose(key);
                  }}
                >
                  <div dangerouslySetInnerHTML={markup} />
                </CheckBoxField>
              }
            </div>
          );
        })}

        <div className={styles.buttons}>
          <ButtonOutline className={styles.prev} onClick={handle_prev}>
            <img src={blue_arrow} alt="previus" />
          </ButtonOutline>
          <Button className={styles.btn_next} onClick={handleSubitQuestion}>
            Next <img src={white_arrow} alt="next" />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Question3;
