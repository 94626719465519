import React, { useState } from "react";

import styles from "./inputs.module.scss";
import { SelectCounties, SelectStates } from "./Selects";

export function Double_Select(props) {
  const { data, onChange } = props;
  const { question } = data;

  const [county, setcounty] = useState({
    img: null,
    full_name: "",
    code: null,
  });

  const handle_county = (val) => {
    setcounty(val);
    onChange(val, "country");
  };

  const handle_state = (val) => {
    onChange(val, "state");
  };

  return (
    <div className={`${styles.select_answer} ${styles.double_select}`}>
      <span className={styles.question}> {question} </span>
      <div className={styles.selects}>
        <SelectCounties onChange={handle_county} />
        <SelectStates onChange={handle_state} code={county.code} />
      </div>
    </div>
  );
}

export default Double_Select;
