import React, { useState } from "react";
import styles from "./inputs.module.scss";
import { devider } from "../../Functions/Arrays.functions";

export function SelectBtn(props) {
  const { check, children, onClick } = props;
  return (
    <div
      onClick={onClick}
      className={`${styles.sel_btn} ${
        check ? styles.sel_actived : styles.not_sel_actived
      }`}
    >
      {children}
    </div>
  );
}

export function SelectAnswer(props) {
  const { data, onChange, name } = props;
  const { question, answers } = data;
  const [selected, setselected] = useState(-1);

  const get_pos = (val1, val2) => {
    return Number(val1) * 3 + Number(val2);
  };

  const handle_select = (key) => {
    //let pos = get_pos(key1, key2);
    console.log(key);
    setselected(key);
    onChange(answers[key], name);
  };

  return (
    <div className={styles.select_answer}>
      <span className={styles.question}> {question} </span>
      <div className={styles.group}>
        {/* {devider(answers, 3).map((group, key1) => {
          return (
            <div key={key1} className={styles.group}>
              {group.map((item, key2) => {
                return (
                  <SelectBtn
                    key={key2}
                    check={selected === get_pos(key1, key2)}
                    onClick={() => handle_select(key1, key2)}
                  >
                    {item}
                  </SelectBtn>
                );
              })}
            </div>
          );
        })} */}

        {answers.map((item, key) => {
          return (
            <SelectBtn
              key={key}
              check={selected === key}
              onClick={() => handle_select(key)}
            >
              {item}
            </SelectBtn>
          );
        })}
      </div>
    </div>
  );
}
