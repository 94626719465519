import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import toast from "react-hot-toast";

import { H2 } from "../../../Components/Texts/Textes";
import { Button, ButtonOutline } from "../../../Components/buttons/Buttons";

import white_arrow from "../../../Assets/Images/icons/white_arrow.svg";
import blue_arrow from "../../../Assets/Images/icons/blue_arrow.svg";

import Data from "../../../data/fake.data";

import { useDispatch } from "react-redux";
import ResultActions from "../../../redux/Actions/Results.action";
import coffeti_actions from "../../../redux/Actions/Cofetti.action";

import TextField from "../../../Components/input/TextField";
import { CheckBox_Field } from "../../../Components/input/CheckBox_Field";

function Question2(props) {
  const { question, handle_next, handle_prev } = props;
  const data = Data.Question2;
  const dispatch = useDispatch();
  const [isnon, setIsNon] = useState(false);
  const [form, setForm] = useState({ ans1: "", ans2: "", ans3: "" });
  const [coff_done, setCoffDone] = useState(false);

  useEffect(() => {
    if (form.ans1.length > 1 || form.ans2.length > 1 || form.ans3.length > 1) {
      if (coff_done) {
        dispatch(coffeti_actions.start_cofetti());
      }
      setCoffDone(true);
    }
  }, [form, coff_done]);

  const handle_change = (event) => {
    setIsNon(false);
    const { name, value } = event.target;
    if (value.length >= 30) {
      toast.error("max is 30 characters");
      return;
    }
    setForm({ ...form, [name]: value });
  };

  const handle_none = () => {
    if (!isnon) {
      setIsNon(true);
      setCoffDone(false);
      setForm({ ans1: "", ans2: "", ans3: "" });
      dispatch(coffeti_actions.start_cofetti());
    } else {
      setIsNon(false);
    }
  };

  const handleSubitQuestion1 = () => {
    if (form.ans1 === "" && form.ans2 === "" && form.ans3 === "" && !isnon) {
      toast.error("please write at least 1 answer");
      return;
    }

    if (isnon) {
      handle_next();
      return;
    }

    dispatch(ResultActions.ChangeQ2([form.ans1, form.ans2, form.ans3].filter(Boolean)));
    // dispatch(coffeti_actions.start_cofetti());
    handle_next();
  };

  return (
    <div className={styles.question}>
      <H2>{question.title}</H2>
      {/* <span className={styles.info}>{data.hint}</span> */}
      <div className={styles.form}>
        {data.answers.map((answer, key) => {
          return (
            <TextField
              value={form[answer.name]}
              onChange={handle_change}
              {...answer}
              key={key}
            />
          );
        })}

        <CheckBox_Field check={isnon} onClick={handle_none}>
          None
        </CheckBox_Field>
        <div className={styles.buttons}>
          <ButtonOutline className={styles.prev} onClick={handle_prev}>
            <img src={blue_arrow} alt="previus" />
          </ButtonOutline>
          <Button className={styles.btn_next} onClick={handleSubitQuestion1}>
            Next <img src={white_arrow} alt="next" />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Question2;
