import React from "react";
import Confetti from "react-confetti";
import useWindowSize from "../../Hooks/useWindowSize";
import { useSelector } from "react-redux";

function Coffetti() {
  const { width, height } = useWindowSize();
  const start = useSelector((state) => state.CofettiReducer);
  return start ? <Confetti width={width} height={height} /> : <div />;
}

export default Coffetti;
