import Keys from "../Keys/Cofetti.keys";

const InitialState = false;

export const CofettiReducer = (state = InitialState, action) => {
  switch (action.type) {
    case Keys.START:
      return true;
    case Keys.STOP:
      return false;
    default:
      return state;
  }
};
