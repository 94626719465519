import Keys from "../Keys/Results.views4.keys";

const ChangeQ1 = (value) => {
  return (dispatch) => {
    dispatch({
      type: Keys.QUESTION1,
      value: value,
    });
  };
};

const ChangeQ2 = (value) => {
  return (dispatch) => {
    dispatch({
      type: Keys.QUESTION2,
      value: value,
    });
  };
};

const ChangeQ2Other = (value) => {
  return (dispatch) => {
    dispatch({
      type: Keys.QUESTION2_OTHER,
      value: value,
    });
  };
};

const ChangeQ3 = (value) => {
  return (dispatch) => {
    dispatch({
      type: Keys.QUESTION3,
      value: value,
    });
  };
};

const ChangeQ4 = (value) => {
  return (dispatch) => {
    dispatch({
      type: Keys.QUESTION4,
      value: value,
    });
  };
};

const ChangeQ4Other = (value) => {
  return (dispatch) => {
    dispatch({
      type: Keys.QUESTION4_OTHER,
      value: value,
    });
  };
};

const ChangeAbout_User = (value) => {
  return (dispatch) => {
    dispatch({
      type: Keys.UPDATE_ABOUT_USER,
      value: value,
    });
  };
};

const actions = {
  ChangeQ1,
  ChangeQ2,
  ChangeQ3,
  ChangeQ4,
  ChangeAbout_User,
  ChangeQ2Other,
  ChangeQ4Other
};

export default actions;
