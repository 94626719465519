export const devider = (tab, nb_slices) => {
  let result = [];
  let slice = [];
  for (let i = 0; i < tab.length; i++) {
    slice.push(tab[i]);
    if ((i + 1) % nb_slices === 0 && i !== 0) {
      result.push(slice);
      slice = [];
    }
  }
  if (slice.length !== 0) {
    result.push(slice);
  }
  return result;
};

export const Gen_Alt = (number) => {
  let tab = [];
  let alt = false;
  for (let i = 0; i < number; i++) {
    tab.push(alt);
    alt = !alt;
  }
  return tab;
};

export const SelectN = (tab, elem) => {
  let pos = tab.indexOf(elem);
  let len = tab.length;

  if (pos === -1) {
    return [elem, ...tab.filter((item, key) => key !== len - 1)];
  }

  if (pos !== -1) {
    return [...tab.filter((item) => item !== elem), -1];
  }
};

export const have = (tab, elem) => {
  return tab.indexOf(elem) !== -1;
};

export const is_last = (tab, pos) => {
  return tab.length - 1 === pos;
};

export const count = (tab, x) => {
  return tab.filter((item) => item === x).length;
};

export const isAll = (tab, x) => {
  return count(tab, x) === tab.length;
};
